<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Prescriptions</h3>
          <div>
            <router-link :to="{name: 'merchant_prescription_orders'}" class="ps-btn btn-sm"><i class="icon-cart-add"></i> Orders</router-link>
            &nbsp;&nbsp;
            <router-link :to="{name: 'merchant_prescription_requests'}" class="ps-btn btn-sm"><i class="icon-hand-waving"></i> Requests</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="prescription in prescriptions" :key="prescription.id">
                  <td>{{ prescription.code }}</td>
                  <td>{{ prescription.name }}</td>
                  <td>
                      <span v-if="prescription.status.name === 'pending'" class="text-warning">{{ prescription.status.label }} </span>
                      <span v-if="prescription.status.name === 'active'" class="text-success">{{ prescription.status.label }} </span>
                      <span v-if="prescription.status.name === 'deleted'" class="text-danger">{{ prescription.status.label }} </span>
                  </td>
                  <td>
                    <router-link :to="{name: 'merchant_edit_prescriptions', params: {id: prescription.id}}">
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="(!meta || meta.total === 0) && !isLoading">
                  <td colspan="5" class="text-center m-4 text-danger">No prescriptions available</td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="5" class="text-center m-4 text-danger">Loading prescriptions...</td>
                </tr>
                <tr v-else>
                  <td class="text-right" colspan="7">
                    <ul class="pagination text-right">
                      <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                        <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                      </li>
                      <li class="active"><a href="#">Page {{ pageNumber }}</a></li>
                      <!--                      <li><a href="#">3</a></li>-->
                      <li v-on:click="goToNextPage()" v-if="hasNextPage">
                        <a href="#">Next Page<i class="icon-chevron-right"></i></a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "List",
  data() {
    return {
      prescriptions: [],
      meta: null,
      isLoading: false,
      isDeleting: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1
    }
  },
  mounted() {
    this.getPrescriptions()
  },
  methods: {
    getPrescriptions() {
      this.isLoading = true;
      let url = 'prescriptions/list';
      if (this.url) {
        url = this.url;
      }

      axios.get(url).then((response) => {
        this.isLoading = false;
         this.prescriptions = response.data.data;
         this.meta = response.data.meta;
      }).catch((error) => {
        this.isLoading = false;
      });
    },
    deletePrescription(id) {
      this.loading = true;
      axios.delete('prescriptions').then((response) => {
        if (response.status === 200 || response.status === 201) {
          location.reload();
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error.response);
        this.loading = false;
      });
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    },

    goToNextPage: function()
    {
      this.url = this.nextPage;
      if (this.url) {
        this.getProducts()
      }
      this.scrollTop();
    },

    goToPrevPage: function()
    {
      this.url = this.prevPage;
      if (this.url) {
        this.getProducts()
      }
      this.scrollTop();
    },

    handleNextPage(links)
    {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false
      }
    },

    handlePrevPage(links)
    {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false
      }
    }
  }
}
</script>

<style scoped>

</style>